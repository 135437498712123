import React, { useEffect, useState, useContext } from 'react';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

import Highlight from "../components/Highlight";
import Loading from "../components/Loading";

import Button from '../components/Button';

import UserContext from '../context/UserContext';

import { usePortal } from '../utils/usePortal';

export const ProfileComponent = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = useState(null);

  const userData = useContext(UserContext);
  

  const { handlePortal } = usePortal();

  useEffect(() => {
    const fetchAccessToken = async () => {
      const token = await getAccessTokenSilently();
      setAccessToken(token);
    };

    fetchAccessToken();
  }, [getAccessTokenSilently]);


  return (
    <div className="mb-20">
      <div className="flex items-center mb-20 text-center md:text-left">
        <div className="md:w-1/6">
          <img
            src={user.picture}
            alt="Profile"
            className="rounded-full w-full h-auto mb-12 md:mb-0"
          />
        </div>
        <div className="md:w-5/6">
          <h2 className="text-2xl">{user.name}</h2>
          <p className="leading-relaxed text-gray-600">{user.email}</p>
        </div>
      </div>
      <div>
        <Highlight>{JSON.stringify(user, null, 2)}</Highlight>
        <Highlight>{JSON.stringify(accessToken, null, 2)}</Highlight>

        <Button onClick={() => handlePortal(userData.stripe_id)} text="Manage Subscription" className="bg-gray-200" />

      </div>
    </div>
  );
};

export default withAuthenticationRequired(ProfileComponent, {
  onRedirecting: () => <Loading />,
});