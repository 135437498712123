import { useCallback, useContext } from 'react';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from '../components/Loading';
import UserContext from '../context/UserContext';

import { ENV } from '../env';

export function usePortal() {

    const { getAccessTokenSilently, loginWithRedirect, isAuthenticated } = useAuth0();

    const handlePortal = useCallback(async (stripe_id) => {


        try {
            if (!isAuthenticated) {
                console.log(window.location.origin + '/pricing');
                loginWithRedirect(
                    {
                        redirectUri: window.location.origin + '/pricing'
                    }
                );
                return;
            }
            const token = await getAccessTokenSilently();

            const response = await fetch(`${ENV.REACT_APP_API_ENDPOINT}/create-portal-session`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`, // replace authToken with your actual token
                },
                body: JSON.stringify({ stripe_id: stripe_id  }),
            });

            if (!response.ok) {
                const error = await response.json();
                throw new Error(error.message);
            }

            const { data } = await response.json();

            console.log(data);

            const url = data.portalSession.url;

            window.location.href = url;

        } catch (error) {
            console.error('There was a problem:', error);
        }
    }, []);
    return { handlePortal };

}
export default withAuthenticationRequired(usePortal, {
    onRedirecting: () => <Loading />,
});


