import React, { useState, useEffect, useRef } from "react";
import LinkedInProfile from "./LinkedInProfile";

import profileBg from "../assets/linkedin-profile-bg.png";
import profilePic from "../assets/linkedin-profile-pic.png";

import LinkedInMessage from "./LinkedInMessage";

import { SmoothCorners } from "react-smooth-corners";

const profiles = [
  {
    profileBackground: profileBg,
    profilePicture: profilePic,
    name: "Jane Smith",
    jobTitle: "Product Manager",
    location: "New York City Area",
    connections: "300+",
    promptName: "Sell",
    prompt: "Sell this pen to me.",
    response:
      "This pen is a great tool for writing. It is comfortable to hold and writes smoothly. It is also very affordable. Would you like to buy it?",
  },
  {
    profileBackground: profileBg,
    profilePicture: profilePic,
    name: "Alice Johnson",
    jobTitle: "Data Scientist",
    location: "Seattle Area",
    connections: "700+",
    prompt:
      "I need to hire datascientists for a space mission. It is located in Canada.",
    promptName: "Recruit",
    response:
      "Hi Alice, I saw your impressive background in data science. We have a space mission in Canada and we are looking for data scientists. Would you be interested in joining us?",
  },
  {
    profileBackground: profileBg,
    profilePicture: profilePic,
    name: "John Doe",
    jobTitle: "Software Engineer",
    location: "San Francisco Bay Area",
    connections: "500+",
    prompt: "Connect with people and focus on our similarities.",
    promptName: "Connect",
    response:
      "Hi John, I see we are both in the software engineering field. I would love to connect with you and learn more about your work.",
  },
];

const Demo = () => {
  const [selectedProfile, setSelectedProfile] = useState(profiles[0]);
  const [showMessage, setShowMessage] = useState(false);
  //const profileIndex = useRef(0); // Add this line

  const handleModeClick = (profile) => {
    setShowMessage(false);
    setSelectedProfile(profile);
    setShowMessage(true);
    //profileIndex.current = profiles.findIndex((p) => p === profile); // Add this line
  };

  const handleHellooButtonClick = () => {
    setSelectedProfile(selectedProfile);
    setShowMessage(true);
  };

  const closeMessage = () => {
    setShowMessage(false);
  };

  //   useEffect(() => {
  //     const timer = setInterval(() => {
  //       profileIndex.current = (profileIndex.current + 1) % profiles.length;
  //       handleModeClick(profiles[profileIndex.current]);
  //     }, 10000); // Change profiles every 10 seconds

  //     return () => {
  //       clearInterval(timer);
  //     };
  //   }, []); // Empty dependency array means this effect runs once on mount and clean up on unmount

  return (
    <div className="text-center hero my-6">
      <div className="flex flex-col justify-center items-center rounded-xl p-1 mb-8">
        <div className="flex justify-center space-x-2 mb-1">
          {profiles.map((profile, index) => (
            <div
              key={index}
              onClick={() => handleModeClick(profile)}
              className={`px-4 py-2 cursor-pointer text-xl font-semibold rounded-full ${selectedProfile?.promptName === profile.promptName ? "bg-gradient-to-r from-blue-600 to-secondary-500 text-white shadow-md" : "bg-none text-black hover:bg-gray-100"} transition-all`}
            >
              {profile.promptName}
            </div>
          ))}
        </div>
      </div>
      <div className="relative flex flex-col-reverse order-3 md:items-start items-center justify-center space-y-2 md:space-y-0">
        <LinkedInProfile
          onHellooButtonClick={() => handleHellooButtonClick()}
          profileBackground={selectedProfile.profileBackground}
          profilePicture={selectedProfile.profilePicture}
          name={selectedProfile.name}
          jobTitle={selectedProfile.jobTitle}
          location={selectedProfile.location}
          connections={selectedProfile.connections}
          />

        <div className="md:absolute md:left-48 order-1 md:bottom-56 sm:bottom-[15rem] shadow-lg w-80 bg-gray-100 rounded-lg border border-gray-200 p-2 mb-2 md:h-14">
          <p className="text-left text-gray-500 text-sm mt-0">
            <span className="font-bold">Your Prompt: </span>
            <span className="text-sm">{selectedProfile?.prompt}</span>
          </p>{" "}
        </div>

          {showMessage && (
            <div className="md:absolute md:left-56 md:top-5 top-72">
              <LinkedInMessage
                response={selectedProfile.response}
                closeMessage={closeMessage}
              />
            </div>
          )}
      </div>
    </div>
  );
};

export default Demo;
