import React from "react";
import logo from "../assets/lg.png";

import { NavbarBrand } from "reactstrap";

const Footer = () => (
  <footer className="bg-light p-2 flex flex-row justify-around text-center items-center">
    <div className="flex flex-col justify-around">
    <p>
      <a href="/privacy">Privacy Policy</a> | <a href="/terms">Terms of Use</a>
    </p>
    <p>© {new Date().getFullYear()} Helloo</p>
    </div>
  </footer>
);

export default Footer;