import React, { useState, useEffect } from 'react';
import { IoCloseSharp } from "react-icons/io5";

const LinkedInMessage = ({ response, closeMessage }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        setCurrentIndex(0); // Reset currentIndex to 0 when response changes

        const timer = setInterval(() => {
            setCurrentIndex((prevIndex) => prevIndex + 1);
        }, 10); // Change this value to adjust the speed

        return () => {
            clearInterval(timer);
        };
    }, [response]); // Add response to the dependency array


    return (
        <div className="p-2 md:mt-10 shadow-lg max-w-80 mx-auto rounded-lg bg-gray-100 border">
            <div className='flex flex-row justify-between'>
                <h6 className="text-gray-800">New message</h6>
                <button 
                    onClick={closeMessage} 
                    className="px-1 py-1 text-gray-800 rounded-full hover:bg-gray-200 focus:outline-none transition-all"
                >
                    <IoCloseSharp />
                </button>
            </div>
            <hr className="border-gray-300 my-0.5" />
            <div className='bg-gray-200 mt-3 rounded-lg p-2'>
                <p className="text-sm text-left">{response.slice(0, currentIndex)}</p>
            </div>
        </div>
    );
};

export default LinkedInMessage;