import React, { useContext, useState, useEffect } from 'react';
import UserContext from '../context/UserContext';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

const Stats = () => {
    const userData = useContext(UserContext);

    return (
        <div className="p-5 bg-gray-100 rounded-3xl md:p-3 md:flex-row">
            <h1 className="text-2xl font-bold mb-4">Stats</h1>
            <div className="mb-4">
                <h2 className="text-xl font-semibold">Subscription: {userData ? userData.subscription_name : <Skeleton /> }</h2>                
                <p className="text-gray-500">Quota: {userData ? userData.quota : <Skeleton />}</p>
            </div>
            <div className="grid grid-cols-2 gap-4">
                <div>
                    <h2 className="text-lg font-semibold mb-2">Total Messages</h2>
                    <p className="text-2xl">{userData ? userData.total_messages : <Skeleton />}</p>
                </div>
                <div>
                    <h2 className="text-lg font-semibold mb-2">Messages left this month</h2>
                    <p className="text-2xl">{userData ? userData.quota : <Skeleton />}</p>
                </div>
            </div>
            </div>
    );
};

export default Stats;