import React from "react";

import Button from "../components/Button";

import { ENV } from "../env";

const ComingSoon = () => {
  return (
    <div className="flex flex-col items-center space-y-4 justify-center p-10 -mt-36 text-center h-screen w-full">
      <h1>
        <span className="text-primary-500">Helloo</span>
        <span> is coming soon...</span>
      </h1>{" "}
      <p>This page is currently under construction.</p>
      <p>
        {" "}
        In the meantime, you can test our pre-release version of the extension
      </p>
      <Button
        text="Install Chrome Extension"
        onClick={() => {
          window.open(ENV.REACT_APP_CHROME_EXTENSION_LINK);
        }}
        className={
          "border-primary-500 border-4 p-2 text-gray-800 rounded-full font-bold hover:bg-primary-500 hover:text-white transition-colors duration-300 focus:outline-none"
        }
      />
    </div>
  );
};

export default ComingSoon;
