import React from "react";
import { Router, Route } from "react-router-dom";
import { Container } from "reactstrap";

import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
import ComplexNavbar from "./components/ComplexNavbar";
import Footer from "./components/Footer";
import Home from "./views/Home";
import Profile from "./views/Profile";
import Dashboard from "./views/Dashboard";
import ExternalApi from "./views/ExternalApi";
import AfterInstall from "./views/AfterInstall";
import Terms from "./views/Terms";
import PrivacyPolicy from "./views/PrivacyPolicy";
import Pricing from "./views/Pricing";
import ComingSoon from "./views/ComingSoon";

import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";

// styles
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
initFontAwesome();


const App = () => {
  const { isLoading, error, isAuthenticated } = useAuth0();

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div id="app" className="d-flex flex-column  bg-gray-50">
      <div className="mt-12">
      <ComplexNavbar />
      </div>
      <Container className="flex-grow-1 mt-5 max-w-6xl bg-gray-50">
        <Route path="/" exact component={isAuthenticated ? Home : Home} />              
        <Route path="/profile" component={ComingSoon} />
        <Route path="/settings" component={ComingSoon} />
        <Route path="/dashboard" component={isAuthenticated ? ComingSoon : Home} />
        <Route path="/successful-chrome-install" component={AfterInstall} />
        <Route path="/terms" component={Terms} />
        <Route path="/privacy" component={PrivacyPolicy} />
        <Route path="/pricing" component={ComingSoon} />
        <Route path="/extension" component={ComingSoon} />
      </Container>
      <Footer />
    </div>
  );
};

export default App;
