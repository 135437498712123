const contentData = [
  {
    title: "1. Download the extension",
    link: "https://www.youtube.com/watch?v=dQw4w9WgXcQ",
    description:
      "This will give you all the functionality"
  },
  {
    title: "2. Pin the extnsion to your browser",
    link: "https://www.youtube.com/watch?v=dQw4w9WgXcQ",
    description:
      "This way you can quickly access it and select and create prompts"
  },
  {
    title: "3. Login to your account",
    link: "https://www.youtube.com/watch?v=dQw4w9WgXcQ",
    description:
      "Currently anyone logged in can access the extension. "
  },
  {
    title: "4. Reach out to people on linkedin",
    link: "https://www.youtube.com/watch?v=dQw4w9WgXcQ",
    description:
      "The more the better"
  }
];

export default contentData;
