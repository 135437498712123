import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import LinkedInProfile from "./LinkedInProfile";
import Demo from "./Demo";
import arrow from "../assets/arrow.svg";
import Button from "./Button";
import { ENV } from "../env";

import { SmoothCorners } from "react-smooth-corners";

const Hero = () => {
  const { user, isAuthenticated, loginWithRedirect } = useAuth0();

  return (
    <div className="text-center my-2 md:my-5 max-w-5xl mx-auto">
      <h1 className="mb-3 md:mb-4 md:mt-16">
        Helloo personalizes
        <br />
        LinkedIn Messages with AI
      </h1>
      <Demo />
      <h1 className="mt-20">
        <span className="text-primary-500">Helloo</span>
        <span> will launch soon...</span>
      </h1>{" "}
      <div className="flex flex-col items-center space-y-2 justify-center w-full mb-36">
        <p>
          In the meantime you can test our pre-release version of the extension
        </p>
        <Button
          text="Install Chrome Extension"
          onClick={() => {
            window.open(ENV.REACT_APP_CHROME_EXTENSION_LINK);
          }}
          className={
            "border-primary-500 border-4 p-2 text-gray-800 rounded-full font-bold hover:bg-primary-500 hover:text-white transition-colors duration-300 focus:outline-none"
          }
        />
      </div>
    </div>
  );
};

export default Hero;
