import React from 'react';

const Terms = () => {
    return (
        <div className="p-2 mb-3 ">
            <h1 className="text-xl font-extrabold p-2 text-center">Terms of Use</h1>
            <p>
                Welcome to Helloo.ai. Please read these Terms of Use carefully before using our services.
            </p>
            <p>
                Helloo.ai is an independent service and is not endorsed by, directly affiliated with, or an official product of LinkedIn Corporation, registered in the U.S. and other countries. All LinkedIn™ logos and trademarks displayed on our site are the property of LinkedIn Corporation.
            </p>
            <p>
                Users are responsible for ensuring their use of Helloo.ai complies with all applicable laws and regulations, including LinkedIn's User Agreement. We make no representations or guarantees about the legality or appropriateness of our services in relation to LinkedIn's User Agreement.
            </p>
            <p>
                By using Helloo.ai, you understand and agree that you are using our services at your own risk and discretion. We disclaim all liability arising from or related to your use of our services.
            </p>
        </div>
    );
};

export default Terms;