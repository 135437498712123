import React from "react";
import profileBackground from "../assets/linkedin-profile-bg.png";
import profilePicture from "../assets/linkedin-profile-pic.png";
import star from "../assets/star.svg";

import arrow from "../assets/arrow.svg";

import "./LinkedInProfile.css";

const LinkedInProfile = ({
  onHellooButtonClick,
  profileBackground,
  profilePicture,
  name,
  jobTitle,
  location,
  connections,
}) => {
  // Add your component logic here

  return (
      <div className="relative h-[17rem] max-w-96 w-full rounded-lg shadow-md  bg-gray-100">
        <img
          src={profileBackground}
          alt="LinkedIn Profile"
          className="h-2/5 w-full rounded-lg object-cover"
        />

        <div className="absolute left-4 top-10 w-20 h-20 p-0 rounded-full border-4 border-white">
          <img
            src={profilePicture}
            alt="Profile"
            className="w-full h-full rounded-full object-cover"
          />
        </div>

        <div className="absolute top-28 left-8 text-left">
          <h2 className="text-xl font-semibold pb-0 mb-0">{name}</h2>
          <p className="text-gray-800">{jobTitle}</p>
          <p className="text-gray-500 text-xs">{location}</p>
          <p className="text-xs font-semibold text-secondary-500">
            {connections} connections
          </p>

          <div className="flex sm:flex-row justify-between mt-3 space-x-3 ">
            <button
              className="bg-primary-500 group text-white font-semibold px-4 py-1 rounded-full flex items-center focus:outline-none hover:bg-primary-550 glowing-button border-solid border-2 border-primary-300"
              onClick={onHellooButtonClick}
            >
              <img src={star} alt="Star" className="mr-1 w-4 h-4 transition-all" />
              Helloo
            </button>
            <button
              className="bg-gray-200 text-gray-400 font-semibold px-4 py-1 rounded-full border-solid border-2 border-gray-300"
              disabled
            >
              Message
            </button>
          </div>

          
        </div>
      </div>
  );
};


{/* <div className="absolute right-80 bottom-16">
            <div className=" text-gray-600 text-xs px-2 py-1">Try me </div>
            <img
              src={arrow}
              alt="Arrow"
              className="w-20 h-20 color-blue-300 object-scale-down transform scale-300 transition-transform duration-200"
            />
          </div> */}
export default LinkedInProfile;
