import React from "react";
import pin from "../assets/afterinstall/pin.png";
import login from "../assets/afterinstall/login.png";
import profile from "../assets/afterinstall/SCRN_1.png";

export const AfterInstall = () => {
  return (
    <div className="mb-20 mx-auto">
      <div className="flex flex-col items-center space-x-1 md:space-y-5 mb-5 text-center md:text-left">
        <h1 className="">
          Thank you for installing{" "}
          <span className="text-primary-500">Helloo</span>
        </h1>{" "}
        <h2> To get started please...</h2>
        <div className="flex p-1 md:p-3 flex-col  items-center space-y-5 md:max-w-xl">
          <div className="flex flex-col-reverse md:flex-row space-x-2 items-center space-y-5">
            <img
              src={pin}
              alt="
                        "
              className="w-64 rounded-3xl"
            />
            <div className=" space-x-1 md:space-y-5  p-3">
              <h3>1. Pin the extension</h3>
              <p className=" text-md">
                Click the extensions icon in the browser and click the pin icon
                next to Helloo
              </p>
            </div>
          </div>
          <div className="flex p-1 md:p-3 flex-col-reverse md:flex-row-reverse  items-center space-x-1 md:space-y-5 ">
            <img src={login} alt="" className="w-64 rounded-3xl" />
            <div className="items-center space-x-1 md:space-y-5  p-3">
              <h3>2. Login and configure your prompt</h3>
              <p className=" text-md">
                Click on the icon and signup. Then you can start designing your
                prompts and reach out to people on Linkedin
              </p>
            </div>
          </div>

          <div className="flex flex-col-reverse md:flex-row space-x-2 items-center space-y-5">
            <img
              src={profile}
              alt=""
              className="w-64 rounded-3xl"
            />
            <div className=" space-x-1 md:space-y-5  p-3">
              <h3>3. Say Helloo</h3>
              <p className=" text-md">
                Go to a LinkedIn Profile that you are connected to or to any profile in your network on Sales Navigator
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AfterInstall;
