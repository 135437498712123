import React from 'react';

const Button = ({ onClick, text, className, disabled = false, icon }) => {
  return (
    <button
      onClick={onClick}
      className={` py-2 px-2 font-bold rounded-xl flex justify-around items-center ${className}`}
      disabled={disabled}
    >
      <span>{text}</span>
      {icon && <span className="ml-0">{icon}</span>}
    </button>
  );
};

export default Button;