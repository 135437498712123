import React, { useState, useEffect } from 'react';
import UserContext from './UserContext';
import { useAuth0 } from '@auth0/auth0-react';
import { ENV } from '../env';

const UserProvider = ({ children }) => {
    const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
    const [appUser, setAppUser] = useState(null);

    useEffect(() => {
        const getUserMetadata = async () => {

            try {
                const token = await getAccessTokenSilently();


                const response = await fetch(`${ENV.REACT_APP_API_ENDPOINT}/user`, {

                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    const error = await response.json();
                    throw new Error(error.message);
                }
                const { data } = await response.json();

                console.log(data);

                setAppUser(data);
            } catch (e) {
                console.log(e.message);
            }
        };

        if (isAuthenticated && !isLoading) {
            getUserMetadata();
        }
    }, [isAuthenticated, isLoading, user, getAccessTokenSilently]);

    return (
        <UserContext.Provider value={appUser}>
            {children}
        </UserContext.Provider>
    );
};

export default UserProvider;