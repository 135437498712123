import React from 'react';
import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Stats from '../components/Stats';
import PastMessages from '../components/PastMessages';

const Dashboard = () => {

    //TODO FIX THE ERROR WHEN ONE FETCH OF THE COMPONETNS IS UNATUHORIZED TO REDIRECT TO LOGIN OR SO
    return (
        <div className='bg-gray-50'>
            <h1>Dashboard</h1>
            <div className="">
                <Stats />
            </div>
            <div className=" pt-5">
                <PastMessages />
            </div>
        </div>
    );
}

export default withAuthenticationRequired(Dashboard, {
    onRedirecting: () => <Loading />,
});