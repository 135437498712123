import React, { useState, useEffect, useRef, useContext } from "react";
import { Logo, GetStartedButton } from "./Logo";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import star from "../assets/star.svg";
import UserContext from "../context/UserContext";
import { ENV } from "../env";
import { HiDownload } from "react-icons/hi";
import Button from "./Button";
import { IoIosClose } from "react-icons/io";
import { TbExternalLink } from "react-icons/tb";

function ComplexNavbar() {
  const [isOpen, setIsOpen] = useState(false);
  const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const node = useRef();
  const popupRef = useRef();

  const userData = useContext(UserContext);

  const [upgradePopup, setUpgradePopup] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const showPopup = Math.random() < 0.2; // 20% probability

    if (showPopup) {
      const randomTime = Math.floor(Math.random() * (10000 - 3000) + 3000); // Generates a random number between 3000 and 10000

      const timer = setTimeout(() => {
        setUpgradePopup(true);
      }, randomTime);

      return () => clearTimeout(timer);
    }
  }, []);

  const handleClickOutside = (e) => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setIsOpen(false);
  };

  const handleLogin = () => {
    loginWithRedirect({
      redirectUri: ENV.REACT_APP_AUTH_REDIRECT_URI,
    });
  };

  const handleSignup = () => {
    loginWithRedirect({
      redirectUri: ENV.REACT_APP_AUTH_REDIRECT_URI,
      authorizationParams: { screen_hint: "signup" },
    });
  };

  const logoutWithRedirect = () =>
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });

  useEffect(() => {
    if (isOpen) {
      // add when mounted
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      // return function to be called when unmounted
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      // cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    if (upgradePopup) {
      setIsVisible(true);
    }
  }, [upgradePopup]);

  return (
    <div className="">
      <nav className="bg-gray-50 bg-opacity-50	backdrop-blur-xl p-2 fixed top-0 w-full z-50 max-h-20">
        <div className="flex items-center justify-between">
          <div className="flex flex-row items-center">
            <Link to="/">
              <Logo />
            </Link>
            <div className="flex flex-row group">
              <a
                href={ENV.REACT_APP_CHROME_EXTENSION_LINK}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center"
              >
                <HiDownload className=" text-primary-500 block text-3xl hover:bg-gray-200 group-hover:rotate-6 mx-2 rounded-full p-1 transition-all" />
                <span className="group-hover:text-primary-500 hidden sm:block text-gray-400 transition-all">
                  Download Chrome Extension
                </span>
              </a>
            </div>
          </div>
          <div className="flex flex-row items-center">
            {!isAuthenticated && (
              <Link to="pricing" className="block">
                <Button
                  className="transition-colors focus:outline-none hover:bg-gray-200"
                  text={"Pricing"}
                ></Button>
              </Link>
            )}

            {!isAuthenticated && (
              <div className="flex flex-row">

                <Button
                  className="hidden sm:block transition-colors focus:outline-none hover:bg-gray-200"
                  onClick={handleSignup}
                  text={"Signup"}
                ></Button>
                <Button
                  onClick={handleLogin}
                  className="transition-colors focus:outline-none hover:bg-gray-200"
                  text={"Login"}
                ></Button>
              </div>
            )}

            {isAuthenticated && (
              <div className="relative" ref={node}>
                <div className="rounded-full hover:bg-gray-200 p-2 transition-colors duration-200">
                  <img
                    src={user.picture}
                    alt="Profile"
                    className="nav-user-profile rounded-circle"
                    width="35"
                    onClick={() => setIsOpen(!isOpen)}
                  />
                </div>
                {isOpen && (
                  <div className="absolute right-0 mt-2 w-72 bg-gray-50 border border-gray-200 p-1  rounded-2xl shadow-lg z-10">
                    <Link
                      to="/profile"
                      onClick={() => setIsOpen(false)}
                      className="block px-3 py-2.5 text-lg m-1 rounded-xl text-gray-700 hover:bg-gray-100"
                    >
                      Your Profile
                    </Link>
                    <Link
                      to="/dashboard"
                      onClick={() => setIsOpen(false)}
                      className="block px-3 py-2.5 text-lg m-1 rounded-xl text-gray-700 hover:bg-gray-100"
                    >
                      Dashboard
                    </Link>
                    <Link
                      to="/settings"
                      onClick={() => setIsOpen(false)}
                      className="block px-3 py-2.5 text-lg m-1 rounded-xl text-gray-700 hover:bg-gray-100"
                    >
                      Settings
                    </Link>
                    <Link
                      to="/extension"
                      onClick={() => setIsOpen(false)}
                      className="block px-3 py-2.5 text-lg m-1 rounded-xl text-gray-700 hover:bg-gray-100"
                    >
                      Install Chrome Extension
                    </Link>
                    <Link
                      to="/logout"
                      onClick={() => {
                        setIsOpen(false);
                        logoutWithRedirect();
                      }}
                      className="block px-3 py-2.5 text-lg m-1 rounded-xl text-gray-700 hover:bg-gray-100"
                    >
                      Sign out
                    </Link>

                    {/*userData &&
                      (userData.subscription_name === "Free" ||
                      userData.subscription_name === "Basic") && (
                        <div>
                        <hr className="border-gray-300 mx-4 py-1" />
                        <Link
                          to="/pricing"
                          onClick={() => setIsOpen(false)}
                          className="flex items-center justify-between px-3 py-2.5 text-lg font-semibold m-1 rounded-xl text-white bg-gradient-to-r from-blue-600 to-secondary-600 transition-color"
                        >
                          Upgrade for GPT-4
                          <img
                            src={star}
                            alt="Star"
                            className=" ml-2 w-6 h-6 transition-all"
                          />
                        </Link>
                        </div>

                      )*/}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </nav>

      {/*upgradePopup &&
        userData &&
        (userData.subscription_name === "Free" ||
          userData.subscription_name === "Basic") && (
          <div
            ref={popupRef}
            className={`pb-1 pt-3 px-6 w-full bg-gradient-to-r from-blue-600 to-secondary-500 flex flex-row text-gray-100 justify-between items-center transition-all duration-500 ease-in-out transform ${isVisible ? "fixed top-14 translate-y-0" : ""}`}
          >
            <p className="font-semibold">
              {userData.subscription_name === "Free" &&
                `Heyoo, you've only got ${userData.quota} messages left. Upgrade for more!`}
              {userData.subscription_name === "Basic" &&
                `Heyoo, upgrade for Sales Navigator and GPT-4o!`}
            </p>
            <div className="flex items-center">
              <Link
                to="/pricing"
                className=" hover:text-gray-200 transition-all font-bold"
              >
                {" "}
                Upgrade to Professional
              </Link>
              <IoIosClose
                onClick={() => {
                  setUpgradePopup(false);
                  setIsVisible(false);
                }}
                className="cursor-pointer ml-2 h-6 w-6 hover:text-gray-200"
              />
            </div>
          </div>
        )*/}
    </div>
  );
}

export default ComplexNavbar;
