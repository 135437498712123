import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "@auth0/auth0-react";
import history from "./utils/history";
import { getConfig } from "./config";
import { Router, Route, Switch } from "react-router-dom";
import WhitePage from "./views/WhitePage";
import UserProvider from "./context/UserProvider";
import { ENV } from "./env";

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

// Please see https://auth0.github.io/auth0-react/interfaces/Auth0ProviderOptions.html
// for a full list of the available properties on the provider
const config = getConfig();


const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  onRedirectCallback,
  authorizationParams: {
    redirect_uri: window.location.origin,
    ...(config.audience ? { audience: ENV.REACT_APP_API_ENDPOINT } : null),
  },
};

const root = createRoot(document.getElementById('root'));
root.render(
  <Router history={history}>
    <Switch>
      <Route path="/oauth/chrome/callback" component={WhitePage} />
      <Auth0Provider {...providerConfig}>
        <UserProvider>
          <App />
        </UserProvider>
      </Auth0Provider>
    </Switch>
  </Router >
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
