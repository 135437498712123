import React from "react";
import star from "../assets/star.svg";
import { SmoothCorners } from 'react-smooth-corners'

const Logo = () => {
  return (
    
    <button className="rounded-full shadow-md bg-gradient-to-r from-primary-525 to-primary-500 py-2 px-3 flex items-center focus:none hover:bg-primary-525 transition-colors focus:outline-none">
      <img src={star} alt="Star" className="mr-2 w-6 h-6 mb-1 transition-all" />
      <h3 className="text-white m-0">Helloo</h3>
    </button>
  );
};

const GetStartedButton = () => {
  return (
    <button className="rounded-full shadow-md bg-gradient-to-r from-secondary-400 to-secondary-500 py-2 px-3 flex items-center focus:none hover:bg-secondary-600 transition-colors focus:outline-none">
      <h4 className="text-white m-0">Login</h4>
    </button>
  );
};

export { Logo, GetStartedButton };
