import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useAuth0 } from "@auth0/auth0-react";
import { ENV } from '../env';

const PastMessages = () => {
    const { getAccessTokenSilently } = useAuth0();
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchMessages = async () => {
            try {
                const token = await getAccessTokenSilently();
                const response = await fetch(`${ENV.REACT_APP_API_ENDPOINT}/user/message`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Limit': '10' // replace with the desired limit
                    }
                });
                const { data } = await response.json();
                console.log(data);
                setMessages(data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching messages:', error);
            }
        };

        fetchMessages();
    }, [getAccessTokenSilently]);

    return (
        <div>
    <h2>{loading ? <Skeleton /> : 'Past Messages'}</h2>
    {loading ? (
        Array(10).fill().map((_, i) => (
            <div key={i} style={{ borderBottom: '1px solid #ddd', padding: '10px 0' }}>
                <Skeleton />
            </div>
        ))
    ) : (
        <table style={{ width: '100%' }}>
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Date</th>
                    <th>Tokens Input</th>
                    <th>Tokens Output</th>
                </tr>
            </thead>
            <tbody>
                {messages && messages.map((message) => (
                    <tr key={message.id}>
                        <td>{message.id}</td>
                        <td>{new Date(message.time_stamp).toLocaleDateString()}</td>
                        <td>{message.tokens_input}</td>
                        <td>{message.tokens_output}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    )}
</div>
    );
};

export default PastMessages;