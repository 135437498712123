import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className="p-2 mb-3">
            <h1 className="text-xl font-extrabold p-2 text-center">Privacy Policy</h1>
            <h2 className="text-xl font-bold p-2">1. Introduction</h2>
            <p>We appreciate your choice to use helloo.ai. At helloo.ai, your privacy is our top priority, and we are committed to safeguarding your personal information. This document explains our practices regarding the collection, use, and protection of your personal data.</p>

            <h2 className="text-xl font-bold p-2">2. Information We Collect</h2>
            <p>• Directly Provided Data: When you sign up with helloo.ai, we collect personal details such as your name, email address, and company information.</p>
            <p>• Usage Data: We monitor how you interact with our platform, noting the pages you visit, the duration of your visit, and the features you use.</p>
            <p>• Data from Third-Parties: We employ tools like Google Analytics to obtain aggregated data about usage to enhance optimization and improve user experience.</p>

            <h2 className="text-xl font-bold p-2">3. How We Use Your Information</h2>
            <p>• Service Delivery: To maintain, enhance, and provide our services.</p>
            <p>• Communication: To keep you informed about product updates, promotional offers, and customer service interactions.</p>
            <p>• Optimization: To evaluate usage trends and improve the user interface, as well as to develop new functionalities.</p>
            <p>• Legal and Compliance: To fulfill legal requirements and protect our legal rights.</p>

            <h2 className="text-xl font-bold p-2">4. Sharing and Disclosure</h2>
            <p>helloo.ai does not sell your information. We share your data with third parties only when:</p>
            <p>• Necessary for delivering our services (e.g., cloud storage services).</p>
            <p>• Mandated by law or for regulatory purposes.</p>

            <h2 className="text-xl font-bold p-2">5. Third-Party Tools</h2>
            <p>We use third-party tools to help with analytics and service optimization:</p>
            <p>• Google Analytics: Utilized to analyze traffic and interactions on our website.</p>
            <p>Please review the privacy policies of these third-party services to understand their data handling practices.</p>

            <h2 className="text-xl font-bold p-2">6. Data Protection</h2>
            <p>helloo.ai implements strict security protocols to secure your data, including encryption, secure server deployment, and frequent security evaluations. We pledge to respond swiftly to any security incidents and to notify affected individuals.</p>

            <h2 className="text-xl font-bold p-2">7. Your Rights</h2>
            <p>You possess the right to:</p>
            <p>• Access the information we hold about you.</p>
            <p>• Request correction of any inaccurate data.</p>
            <p>• Request deletion of your data.</p>
            <p>• Object to specific forms of data processing.</p>

            <h2 className="text-xl font-bold p-2">8. Cookies</h2>
            <p>Our platform uses cookies to enhance functionality, remember user preferences, and facilitate analytical processes. Opting out of cookies may impair the functionality of our website.</p>

            <h2 className="text-xl font-bold p-2">9. Changes to This Policy</h2>
            <p>We reserve the right to modify this Privacy Policy occasionally. Significant changes will be communicated to users, and we encourage regular review of this policy.</p>

            <h2 className="text-xl font-bold p-2">10. Contact Us</h2>
            <p>If you have any questions or concerns about this Privacy Policy or how we handle your data, please contact us at hello@helloo.ai.</p>
        </div>
    );
};

export default PrivacyPolicy;